.mapStyling {
  width: 100%;
  height: 720px;
  position: relative;
  box-sizing: border-box;
}

.tooltip {
  pointer-events: none;
  z-index: 9;
  background: #000;
  color: #fff;
  width: 350px;
  min-height: 80px;
  max-height: 500px;
  box-sizing: border-box;
  display:inline-block;
  position: sticky;
  top: 80px;
  left: 1080px;
}

.tooltip.interactive {
  pointer-events: all;
  background: #fcfcfc;
  color: #444;
}

.divStyle {
  width: 100%;
  height: 100%;
  padding: 16px 16px 0px 16px;
}

.homeStyling {
  background-color: #f9fafa;
  border-width: 0px !important;
}

.homeTableStyling{
  border-width: 0px !important;
}