* {
  box-sizing: border-box;
}

body {
  font-family: "Amazon Ember";
  ;
}

main {
  overflow-x: hidden;
}

.stickyToLeft {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
}