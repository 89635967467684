.footerMenu{
    display: block;
    float: none;
    line-height: 24px;
    position: relative;
    z-index: 0;
    width: 100%;
    min-height: 40px;
    outline: none;
    color: rgb(35, 47, 62);
    cursor: pointer;
    text-decoration: none;
  }
  
  .footerFontStyle {
    color: rgb(35, 47, 62);
    font-size: 16px;
    font-weight: 400;
    padding-top: 8px;
    padding-right: 24px;
    padding-bottom: 8px;
    padding-left: 0;
  }
  
  .footerMenu:hover {
    background-color: rgb(240, 241, 242);
  }
  