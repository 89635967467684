.wrapContent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.projects-toggle-no-checked-button {
  background-color: #077398 !important;
  width: 20px !important;
  height: 20px !important;
  color: white !important;
  padding: 0 !important;
}

.projects-toggle-all-checked-button {
  background-color: #077398 !important;
  width: 20px !important;
  height: 20px !important;
  color: white !important;
}

.sticky-left-0 thead > tr > th:nth-of-type(2),
.sticky-left-0 tbody > tr > th:nth-of-type(2) {
  position: sticky !important;
  left: 2% !important; // the value is from width of checkbox column
  z-index: 42 !important;
}